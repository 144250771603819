<template>
    <ui-modal
        ref="warningModal"
        centered
        title="Are you still with us?"
        backdrop="static"
        hide-header-close
        :keyboard="false"
    >
        <p>
            We have detected that you have been inactive for
            {{  Math.round(expireInSeconds() / 60) }} minutes. Your session will be closed in:
            <strong>{{ Math.round(countDown) }}s</strong>
        </p>
        <template #footer>
            <ui-button variant="light" @click="logoutUser">
                Logout
            </ui-button>
            <ui-button variant="primary" @click="keepSession">
                Keep session
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button'
import UiModal from '@/components/ui/Modal.vue';

import throttle from '@/utils/throttle.util';

export default {
    name: 'AutoLogout',
    components: { UiButton, UiModal },
    data() {
        return {
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
            warningTimer: null,
            logoutTimer: null,
            refreshTimer: null,
            expiresIn: null,
            secondsToShowModal: 30,
            lastActivity: Date.now(),
            countDown: 0,
            modalIsOpen: false,
        };
    },
    mounted() {
        if (!this._getCookie('_exp') || !this._getCookie('_tid')) return;
        this.setMaxInactivity();
        this.addCustomEvents();
        this.setTimers();
        this.setRefreshTimer();

        setInterval(() => {
            this.countDown = this.expireInSeconds();
        }, 1000);
    },
    methods: {
        expireInSeconds() {
            const value = (this.expiresIn - Date.now()) / 1000;
            return value > 0 ? value : 0;
        },
        inactivityInSeconds() {
            return (Date.now() - this.lastActivity) / 1000;
        },
        setMaxInactivity() {
            this.expiresIn = new Date(this._getCookie('_exp')).getTime();
        },
        addCustomEvents() {
            this.events.forEach((event) => {
                window.addEventListener(event, throttle(this.resetTimer, 300));
            }, this);
        },
        setTimers() {
            if (this.modalIsOpen) return;
            this.warningTimer = setTimeout(this.warningMessage, (this.expireInSeconds() - this.secondsToShowModal) * 1000);
            this.logoutTimer = setTimeout(this.logoutUser, this.expireInSeconds() * 1000);
        },
        setRefreshTimer() {
            const seconds = this.expireInSeconds() - this.secondsToShowModal - 5;
            clearTimeout(this.refreshTimer);
            this.refreshTimer = setTimeout(() => {
                const inactivity = this.inactivityInSeconds();
                if (inactivity < seconds - 10) {
                    this.$axios.post('/_/admins/refresh').then(() => {
                        this.setMaxInactivity();
                        this.resetTimer();
                        this.setRefreshTimer();
                    });
                }
            }, seconds * 1000);
        },
        warningMessage() {
            if (!this._getCookie('_exp') || !this._getCookie('_tid')) return;
            this.$refs.warningModal.show();
            this.modalIsOpen = true;
        },
        async logoutUser() {
            await this.$axios.get('/_/logout')
            this._deleteCookie('_tid');
            window.location.href = '/login';
        },
        async keepSession() {
            await this.$axios.patch('/_/admins/refresh');
            this.$refs.warningModal.hide();
            this.modalIsOpen = false;
            this.setMaxInactivity();
            this.resetTimer();
            this.setRefreshTimer();
        },
        async resetTimer() {
            if (this.modalIsOpen) return;
            this.lastActivity = Date.now();
            clearTimeout(this.warningTimer);
            clearTimeout(this.logoutTimer);
            this.setTimers();
        },
    },
};
</script>
