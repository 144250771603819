const accountsFilter = [
    '/_/accounts',
    '/_/transactions',
    '/_/withdrawals',
    '/_/payouts',
    '/_/chargebacks',
    '/_/refunds',
    '/_/risks',
    '/_/activity',
    '/_/activations',
    '/_/invoices',
    '/_/whitelist',
    '/_/rules',
    '/_/transfers',
];

export default accountsFilter;