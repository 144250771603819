const state = () => ({
    globalStatus: {
        paymentMethod: null,
        contract: null,
        documents: null,
        individual: null,
        business: null,
        riskDocument: null,
        fiscal_situation: null,
        proof_address: null,
    },
    timeLine: null
});

const mutations = {
    updateGlobalStatus(state, newStatus) {
        const statusMap = {
            payment_method: 'paymentMethod',
            contract: 'contract',
            business: 'business',
            documents: 'documents',
            individual: 'individual',
            risk_document: 'riskDocument',
            fiscal_situation: 'fiscal_situation',
            proof_address: 'proof_address',
        };
        const statusKey =  Object.keys(newStatus)[0];
        if (statusMap[statusKey]) {
            state.globalStatus[statusMap[statusKey]] = newStatus[statusKey].status;
        }else {
            console.log('No se encuentra registrado el formulario para el manejo del status');
        }
    },
    updateTimeLine(state, newTimeLine) {
        state.timeLine = newTimeLine;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
};