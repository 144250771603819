export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        icon: {
            type: String,
            default: null,
            required: false,
        },
        image: {
            type: String,
            default: null,
            required: false,
        },
        link: {
            type: String,
            default: null,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        roundedVariant: {
            type: String,
            default: 'md',
            required: false,
        },
        target: {
            type: String,
            default: null,
            required: false,
        },
        to: {
            type: [String, Object],
            default: null,
            required: false,
        },
        type: {
            type: String,
            default: 'button',
            required: false,
        },
        variant: {
            type: String,
            default: 'secondary',
            required: false,
        }
    },
    data() {
        return {
            tag: 'button',
            currentBindings: {},
        };
    },
    created() {
        if (this.to) {
            this.tag = 'router-link';
            this.currentBindings = { to: this.to };
        } else if (this.link) {
            this.tag = 'a';
            this.currentBindings = { target: this.target };
        } else {
            this.currentBindings = { type: this.type };
        }
    },
};
